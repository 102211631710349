<template>
  <section>
      <div class="content-header">
        <h2>Podcasts that discuss resilience</h2>
      </div>
      <div class="content-wrapper">
        <p>There are many excellent podcasts that discuss ways that faculty can foster resilience <strong class="bold">before</strong> entering their physical and/or digital classrooms.</p>
        <p><em><a href="https://dont-call-me-resilient.simplecast.com/" target="_blank">Don’t Call Me Resilient</a></em> is an anti-racist, conversation-based podcast exploring the tensions and harms of “resilience”. Check out this episode about <a href= "https://dont-call-me-resilient.simplecast.com/episodes/ep-5-black-health-matters" target="_blank">COVID-19 disproportionately impacts Black, Indigenous, poor and racialized communities</a> and this one on <a href="https://dont-call-me-resilient.simplecast.com/episodes/ep-3-how-to-spark-change-within-our-unequal-education-system" target="_blank">how to spark change within our unequal education system</a>. </p>
        <!-- <iframe height="200px" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/56491aff-985e-4703-a9df-3c29b1d9d37f?dark=false"></iframe> -->
        <p class="lm">Learn more</p>
        <p>Check out these podcasts that explore the many dimensions, challenges, and perspectives of resilience:  </p>
        <ul>
          <li>Resilience and Resistance Podcast, Episode: July 29, 2021: <a href="https://podcasts.google.com/feed/aHR0cHM6Ly9yc3MuYXJ0MTkuY29tL3Jlc2lsaWVuY2UtYW5kLXJlc2lzdGFuY2U/episode/Z2lkOi8vYXJ0MTktZXBpc29kZS1sb2NhdG9yL1YwLzhpNkdaUjhrRnRKcFd3QkpCYy1lSGNZaWlEajlTZ2ZyV05COFBONnpqVm8?hl=en-CA&ved=2ahUKEwjGiKmSuYvyAhUxU98KHRphCK8QjrkEegQIAxAL&ep=6" target="_blank">Standing in Rage and Power</a></li>
          <li>A diverse group of people from different communities share their resilience journey in <a href="https://resilient-people.simplecast.com/episodes" target="_blank">Resilient People:</a>
            <ul>
          <li>LGBTQ+ advocate on helping others to build resilience through community: <a href="http://resilient-people.simplecast.com/episodes/terrence-rodriguez" target="_blank">November 26th, 2020</a> </li>
          <li>Raising Black boys with Casey Palmer of #blackdadworries: <a href="https://resilient-people.simplecast.com/episodes/raising-black-boys-with-casey-palmer-of-blackdadworries" target="_blank">April 15th, 2021</a></li>
        </ul>
          </li>
        </ul>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '04',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
